<template>
  <div>  
    <v-dialog v-model="dialogVisible" max-width="500px">
      <v-card>
        <v-card-title class="headline">DETAY </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p> {{selectedItem ? selectedItem.LTEXT : 'Veri bulunamadı' }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn   color="error" text @click="closeDialog"> Kapat </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
      <div v-if="userData.role === 'PLSYR' && !$store.state.plasiyerCustomer">
      <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
      <v-alert
        v-if="!$store.state.plasiyerCustomer"
        text
        outlined
        class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption"
        type="warning"
      >
        Lütfen yukarıdan bir müşteri seçin
      </v-alert>
    </div>
  
    <div v-else>   
        <v-row no-gutters>
          <v-col>
            <div id="actions-list">
              <!-- app drawer -->
              <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN' ) && $vuetify.breakpoint.xsOnly" />

              <actions-list-add-new
                v-model="isAddNewActionsSidebarActive"         
                :edit-message-data="productItem"  
                :isDrawerMode="true"
                @refetch-data="fetchActions"
                @save-product-event="saveProductEvent"
              ></actions-list-add-new>
      
              <v-snackbars
                :objects.sync="snackbars"
                width="500"
                top
                right
                transition="slide-y-transition"
              >      
    
                <template v-slot:action="{ close }">
                  <v-btn
                    icon
                    small
                    @click="close()"
                  >
                    <v-icon small>
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-snackbars>
    
           
              <!-- list filters -->
              <v-card
              tile
              :flat="$vuetify.breakpoint.mdAndDown"
              :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
            >
              <v-toolbar
                v-if="!$vuetify.breakpoint.smAndDown"
                :color="$vuetify.breakpoint.mdAndDown ? (isDark ? '#3b355a' : 'transparent') : 'transparent'"
                flat
                :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
              >
                <v-toolbar-title class="text-h6 font-weight-medium">
                  <v-icon large left>
                    {{ icons.mdiFormatListChecks }}
                  </v-icon>
                  Aksiyon Listesi
                </v-toolbar-title>
  
                <v-spacer></v-spacer>
                 
               
                <v-btn              
                  :class="{
                    'bg-gradient-nightDark': !isDark,
                    'bg-gradient-primary': isDark, // isDark ise farklı bir stil uygula
                    'text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text': true
                  }"  
                  depressed
                  x-large
                  @click="actionAdd()"
                  @mouseenter="activeHover = true" @mouseleave="activeHover = false"                  
                >
                  <span >Aksiyon Ekle</span>
                  <span  v-if="activeHover"> <img src="@/assets/images/misc/tukas-yaprak.png" width="20" > </span>   
                </v-btn>
              </v-toolbar>
              <v-expansion-panels v-else v-model="panel" flat tile>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-body-2 font-weight-medium">
                      <v-icon left>
                        {{ icons.mdiFormatListChecks }}
                      </v-icon>
                      Aksiyon Listesi
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0">
                    <v-divider></v-divider>
                    <v-list class="pa-0" color="grey">
                      <v-list-item @click="actionAdd()">
                        <v-list-item-content>
                          <v-list-item-title>Aksiyon Ekle</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon>{{ icons.mdiPlusThick }}</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider></v-divider>                                          
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- table -->

              <v-sheet :style="$vuetify.breakpoint.xsOnly ? 'padding-top:50px' : null" >
                <v-data-table              
                  :headers="tableColumns"
                  :items="actionsListTable"
                  :options.sync="options"
                  :items-per-page="15"
                  fixed-header
                  :height="dataTableHeight"                 
                  :loading="loading"
                >
                  <!-- Text -->
                  <template #[`item.uid`]="{ item }">
                    <div class="text-caption">
                      {{ item.uid }}
                    </div>
                  </template>
    
                  <template #[`item.adsoyad`]="{ item }">
                    {{ item.adsoyad }}
                  </template>
                  <template #[`item.username`]="{ item }">
                    <router-link
                      :to="{ name: 'user-view', params: { id: item.uid } }"
                      class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                    >
                      {{ item.username }}
                    </router-link>
                  </template>
                  
                  <template #[`item.son_giris`]="{ item }">
                    <div v-if="item.son_giris === null"></div>
                    <timeago v-else class="text-caption black--text" :datetime="item.son_giris"></timeago>
                  </template>
                  <template #[`item.kayit_tarihi`]="{ item }">
                    <div class="text-caption black--text">
                      {{ item.kayit_tarihi | moment('Do MMMM YYYY') }}
                    </div>
                  </template>
    
                  <template #[`item.actions`]="{ item }">
                    <v-tooltip bottom color="secondary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          depressed
                          color="nightDark"
                          x-small
                          class="rounded-r-0"
                          v-bind="attrs"
                          @click="actionDetail(item)"
                          v-on="on"
                        >
                          <v-icon small>
                            {{ icons.mdiEye }}
                          </v-icon>                         
                        </v-btn>
                      </template>
                      <span>Ayrıntılar</span>
                    </v-tooltip> 
                  </template>
                </v-data-table>
              </v-sheet>
            </v-card>
            </div>
          </v-col>
        </v-row> 
        
       
        
    </div>

 

  </div>
  </template>
  

  <script>
  import useAppConfig from '@core/@app-config/useAppConfig';
  import store from '@/store'
  import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiMagnify,
  mdiPlusThick,
  mdiShape,
  mdiSquareEditOutline,
  mdiMessageBulleted,
  mdiFormatListChecks
  } from '@mdi/js'
  import {
  computed, onMounted, ref, watch,
  } from '@vue/composition-api'
  import VSnackbars from 'v-snackbars'
  import Vue from 'vue'
  import ActionsListAddNew from './ActionsListAddNew.vue'
  import CustomerList from '@/components/CustomerList.vue'
 
  export default {
    components: {
      ActionsListAddNew,
      VSnackbars,
      CustomerList,
     // ImageUpload,
    },
    setup() {
      const { isDark } = useAppConfig();
      const snackbars = ref([])
      const isAddNewActionsSidebarActive = ref(false)
      const actionsListTable = ref([])    
      const productItem = ref({})
      const kategoriSearch = ref('')
      const tipSearch = ref('')
      const isUploadProductImageActive = ref(false)
      const dataUpload = ref({})
      const userData = JSON.parse(localStorage.getItem('userData'))

      
      const tableColumns = computed(() => [
          {
          text: 'ID',
          value: 'ID',
          sortable: false,
          width: '50px',
          },
          {
          text: 'Aksiyon Tipi',
          value: 'ACTIONTYPE',
          width: '90px',
          },
          {
          text: 'Açıklama',
          value: 'STEXT',
          width: '200px',
          },
          {
          text: 'Aksiyon Tarihi',
          value: 'ACTIONDATE',
          width: '200px',
          },

          {
          text: 'Öncelik',
          value: 'ACTIONPRIO',
          width: '200px',
          },
          {
          text: 'Statü',
          value: 'ACTIONSTATUS',
          width: '100px',
          },
          {
          text: 'Değiştiren',
          value: 'CHANGEDBY',
          width: '150px',
          },
          {
          text: 'Değiştirme Tarihi',
          value: 'CHANGEDAT',
          width: '200px',
          },
          {
          text: 'Oluşturan',
          value: 'CREATEBY',
          width: '150px',
          },
          {
          text: 'Oluşturma Tarihi',
          value: 'CREATEDAT',
          width: '200px',
          },
          {
          text: '',
          value: 'actions',         
          sortable: false,
          width: '20px',
          },
      ])
  
      const idFilter = ref('')
      const totalListTable = ref(0)
      const loading = ref(false)
      const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 15,
      })
      const productTotalLocal = ref([])
  
      const deleteOpenerMsg = item => {
        Vue.swal({
          title: 'Emin misin?',
          text: 'Silme işlemini onaylayın',
          icon: 'question',
          background: '#FF4C51',
          showCloseButton: false,
          showCancelButton: true,
          width: '300px',
          confirmButtonText: 'Onaylıyorum',
          cancelButtonColor: '#FF4C51',
          confirmButtonColor: '#cc3d41',
        }).then(result => {
          if (result.isConfirmed) {
            loading.value = true
            store
              .dispatch('postMethod', {
                method: 'deleteOpenerMsg',
                id: item.id,
              })
              .then(() => {
                snackbars.value.push({
                  message: 'Silindi',
                  color: 'error',
                  timeout: 5000,
                })
  
                fetchActions()
              })
              .catch(e => {
                Vue.swal({
                  title: 'Hata',
                  text: e.message,
                  width: '350px',
                  icon: 'error',
                  background: '#FF4C51',
                  showCloseButton: false,
                  showConfirmButton: true,
                  confirmButtonColor: '#cc3d41',
                })
              })
          }
        })
      }
  
      const saveProductEvent = val => {
        snackbars.value.push(val)
      }
  

    const selectedItem = ref(null);
    const dialogVisible = ref(false); 
    const actionDetail = item => {
      selectedItem.value = item
      dialogVisible.value = true; // Dialog'u aç
    }
  
    const closeDialog = () => {
      dialogVisible.value = false;
      selectedItem.value = null;
    };

    const fetchActions = () => {  
    loading.value = true; // Yükleme durumunu başlat
    const query = {
        options: options.value        
      }      
      const method = 'getCRMActions'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {    
            if (response.error === 0) {
              actionsListTable.value = response.detail              
              loading.value = false
            } 
            else {
              console.error('API hatası:', response.msg);
            }
            loading.value = false; // Yükleme durumunu kapat
        })
        .catch(e => {
            loading.value = false; // Yükleme durumunu kapat
            Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
            });
        });
      };
 

    const actionAdd = () => {
      productItem.value = {}
      isAddNewActionsSidebarActive.value = true
    }

      const saveImageEvent = val => {
      snackbars.value.push(val)
    }

      onMounted(() => {
        fetchActions()
      })
      watch([idFilter, options], () => {
        loading.value = true
        fetchActions()
      })
  
      return {
        tipSearch,
        kategoriSearch,
        productItem,
        actionAdd,
        actionsListTable,
        tableColumns,
        idFilter,
        totalListTable,
        loading,
        options,
        productTotalLocal,
        isAddNewActionsSidebarActive,
        fetchActions,
        
        deleteOpenerMsg,
        snackbars,
        saveProductEvent,
        userData,
        saveImageEvent,
        isUploadProductImageActive,
        dataUpload,
        isDark,
        actionDetail,
        selectedItem,
        dialogVisible,
        closeDialog,
        // icons
        icons: {
          mdiCamera,
          mdiDelete,
          mdiCameraOff,
          mdiShape,
          mdiEye,
          mdiFilter,
          mdiFilterOff,
          mdiPlusThick,
          mdiSquareEditOutline,
          mdiClose,
          mdiMagnify,
          mdiMessageBulleted,
          mdiFormatListChecks
        },
      }
    },
    data() {
      return {     
        itemsPerPage: null,
        panel: null,
        date: null,
        modal: false,
        filterStatus: false,
        dataTableHeight: '',
        height: {
          system: 0,
          top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
          footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
        },
        activeHover : false,       
      }
    },
  
    watch: {
      panel(val) {
        if (val === 0) {
          setTimeout(() => {
            this.height.top = 250
            this.onResize()
          }, 300)
        } else {
          this.height.top = 48
          this.onResize()
        }
      },
    },
  
    mounted() {
      this.$nextTick(() => {
        this.onResize()
        window.addEventListener('resize', this.onResize)
      })
    },
  
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      onResize() {
        this.itemsPerPage = Math.ceil(window.innerHeight / 100) + 4
        if (this.userData.role === 'SUPER') {
          this.dataTableHeight = window.innerHeight - 128
        } else {
          this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
        }
  
        const r = document.querySelector(':root')
        r.style.setProperty('--height', `${(this.dataTableHeight - 100) / this.itemsPerPage}px`)
      }, 
    },
  }
  </script>
  <style lang="scss">
  @import '@core/preset/preset/apps/user.scss';
  </style>
  