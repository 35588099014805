var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("DETAY ")]),_c('v-divider'),_c('v-card-text',[_c('p',[_vm._v(" "+_vm._s(_vm.selectedItem ? _vm.selectedItem.LTEXT : 'Veri bulunamadı'))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Kapat ")])],1)],1)],1),(_vm.userData.role === 'PLSYR' && !_vm.$store.state.plasiyerCustomer)?_c('div',[(_vm.userData.role === 'PLSYR' && _vm.$vuetify.breakpoint.xsOnly)?_c('customer-list'):_vm._e(),(!_vm.$store.state.plasiyerCustomer)?_c('v-alert',{staticClass:"mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption",attrs:{"text":"","outlined":"","type":"warning"}},[_vm._v(" Lütfen yukarıdan bir müşteri seçin ")]):_vm._e()],1):_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"actions-list"}},[((_vm.userData.role === 'PLSYR' || _vm.userData.role === 'FIN' ) && _vm.$vuetify.breakpoint.xsOnly)?_c('customer-list'):_vm._e(),_c('actions-list-add-new',{attrs:{"edit-message-data":_vm.productItem,"isDrawerMode":true},on:{"refetch-data":_vm.fetchActions,"save-product-event":_vm.saveProductEvent},model:{value:(_vm.isAddNewActionsSidebarActive),callback:function ($$v) {_vm.isAddNewActionsSidebarActive=$$v},expression:"isAddNewActionsSidebarActive"}}),_c('v-snackbars',{attrs:{"objects":_vm.snackbars,"width":"500","top":"","right":"","transition":"slide-y-transition"},on:{"update:objects":function($event){_vm.snackbars=$event}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var close = ref.close;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}])}),_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? (_vm.isDark ? '#3b355a' : 'transparent') : 'transparent',"flat":"","height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFormatListChecks)+" ")]),_vm._v(" Aksiyon Listesi ")],1),_c('v-spacer'),_c('v-btn',{class:{
                  'bg-gradient-nightDark': !_vm.isDark,
                  'bg-gradient-primary': _vm.isDark, // isDark ise farklı bir stil uygula
                  'text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text': true
                },attrs:{"depressed":"","x-large":""},on:{"click":function($event){return _vm.actionAdd()},"mouseenter":function($event){_vm.activeHover = true},"mouseleave":function($event){_vm.activeHover = false}}},[_c('span',[_vm._v("Aksiyon Ekle")]),(_vm.activeHover)?_c('span',[_c('img',{attrs:{"src":require("@/assets/images/misc/tukas-yaprak.png"),"width":"20"}})]):_vm._e()])],1):_c('v-expansion-panels',{attrs:{"flat":"","tile":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"text-body-2 font-weight-medium"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFormatListChecks)+" ")]),_vm._v(" Aksiyon Listesi ")],1)]),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('v-divider'),_c('v-list',{staticClass:"pa-0",attrs:{"color":"grey"}},[_c('v-list-item',{on:{"click":function($event){return _vm.actionAdd()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Aksiyon Ekle")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlusThick))])],1)],1),_c('v-divider')],1)],1)],1)],1),_c('v-sheet',{style:(_vm.$vuetify.breakpoint.xsOnly ? 'padding-top:50px' : null)},[_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.actionsListTable,"options":_vm.options,"items-per-page":15,"fixed-header":"","height":_vm.dataTableHeight,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.uid",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.uid)+" ")])]}},{key:"item.adsoyad",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.adsoyad)+" ")]}},{key:"item.username",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'user-view', params: { id: item.uid } }}},[_vm._v(" "+_vm._s(item.username)+" ")])]}},{key:"item.son_giris",fn:function(ref){
                var item = ref.item;
return [(item.son_giris === null)?_c('div'):_c('timeago',{staticClass:"text-caption black--text",attrs:{"datetime":item.son_giris}})]}},{key:"item.kayit_tarihi",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-caption black--text"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.kayit_tarihi,'Do MMMM YYYY'))+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-r-0",attrs:{"depressed":"","color":"nightDark","x-small":""},on:{"click":function($event){return _vm.actionDetail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ayrıntılar")])])]}}],null,true)})],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }